import { inject } from '@angular/core';
import { FormGroup, ValidatorFn } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ValidationMessageFn, ValidationMessageResolver } from '@nuis/common';
import { BasicDataCostForm } from './basic-data-costs.form';

export const eitherEstimatedCostsValidator: ValidatorFn = (control) => {
    if (!(control instanceof FormGroup)) {
        return null;
    }

    const form = control as FormGroup<BasicDataCostForm>;
    return form.value.estimated == null && form.value.estimatedAdditional == null
        ? { createClaimEitherCostsSet: true }
        : null;
};

export function withCreateClaimValidationMessages(): ValidationMessageFn {
    return () => {
        const translate = inject(TranslateService);

        return new Map<string, ValidationMessageResolver>([
            ['createClaimEitherCostsSet', () => translate.instant('validation.createClaimEitherCostsSet')],
        ]);
    };
}
